<template>
  <PopUp :show="modal" @hideModal="hideModal" class="modal--xs" bgColor="rgba(0,0,0,0.4)">
    <ValidationObserver v-slot="{ invalid }" tag="div" class="center-content" ref="resetForm">
      <header class="center-content__header">
        <h3 class="center-content__header_title">Send password reset email</h3>
      </header>
      <section class="form">
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Enter your email"
              :class="{ 'form-control--invalid': errors[0] || error }"
              :validateError="errors[0] || error"
              v-model="email"
              :element="email"
              @input="email = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <div class="center-content__footer">
        <BaseButton
          class="button--sm button--outline button--uppercase"
          text="Cancel"
          :disabled="invalid"
          @click="cancelAction"
        ></BaseButton>
        <BaseButton
          class="button--sm button--black button--uppercase"
          text="Save Changes"
          :loading="loading"
          :disabled="!email"
          @click="sendReset"
        ></BaseButton>
      </div>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: {
    modal: { type: Boolean, default: false },
  },
  components: { PopUp, ValidationObserver, ValidationProvider, BaseInput, BaseButton },
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      error: "getError",
    }),
  },
  methods: {
    ...mapActions("auth", ["apiSendForgot"]),
    ...mapMutations("auth", ["setError"]),
    cancelAction() {
      this.setError(null);
      this.$nextTick(() => {
        this.email = "";
        this.$refs.resetForm.reset();
      });
    },
    hideModal() {
      this.cancelAction();
      this.$emit("hideModal");
    },
    async sendReset() {
      this.loading = true;
      let result = await this.apiSendForgot({ email: this.email });
      if (result) {
        this.hideModal();
      }
      this.loading = false;
    },
  },
};
</script>