<template>
  <div class="form-control">
    <input
      required
      :type="showText ? 'text' : 'password'"
      class="form-control__input"
      :placeholder="placeholder"
      v-model="item"
    />
    <label class="form-control__label">{{ placeholder }}</label>
    <span class="form-control__icon" @click="showText = !showText">
      <IconEyeClose v-if="!showText"></IconEyeClose>
      <IconEye v-else></IconEye>
    </span>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
import IconEyeClose from "@/components/icons/IconEyeClose.vue";
import IconEye from "@/components/icons/IconEye.vue";
export default {
  components: { IconEyeClose, IconEye },
  props: {
    element: { type: [String, Number] },
    placeholder: { type: String },
    icon: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
  },
  data() {
    return {
      showText: false,
      item: "",
    };
  },
  created() {
    this.item = this.element;
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("input", newVal);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  &__icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &__label {
    width: calc(100% - 7.7rem);
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__input {
    height: 9rem;
    padding: 0 7rem 0 2.7rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @include type();
    color: $lightBlack;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    &::placeholder {
      color: $white;
      opacity: 0;
    }
    &:-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &::-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
      font-size: 14px;
    }
  }
  &__input:focus ~ &__label,
  &__input:valid ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    width: auto;
    top: 0;
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
  &--invalid {
    .form-control__input {
      border-color: $error;
    }
  }
  &--valid {
    .form-control__input {
      border-color: $green;
    }
  }
}
</style>